.button-close-modal {
    position: absolute;
    right: 0.18rem;
    top: 0.18rem;
    border: 0;
    font-size: 1rem;
}

.list div {
    margin: 0.8rem 0;
    border-radius: 0.50rem;
    width: max-content;
}

.list div p {
    padding: 0.5rem 3rem;
    display: inline-block;
    width: 18rem;
}

.list div hr {
    margin: 0.5rem 0 0 0
}

.newEmail input{
    padding: 0.5rem 3rem;
    border: 0;
    width: 18rem;
    height: 40px;
}