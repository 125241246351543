/** @format */

.table-header {
  color: #3273dc !important;
  font-weight: 700;
}

.info {
  box-sizing: content-box;
}

.info:hover {
  cursor: initial;
}

.is-purple {
  background-color: #633aa7;
  border-color: transparent;
  color: #fff;
}

.is-purple:hover {
  color: #fff;
  border-color: transparent;
}

.margin-top {
    margin-top: 1em;
}
