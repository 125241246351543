/** @format */

:root {
    --with-panel: 400px;
}

.sidebar {
    height: 95vh;
    width: var(--with-panel);
    position: fixed;
    overflow: scroll;
    padding: 1em;
}

.map {
    height: 90vh;
    width: 100%;
    padding: 0px 0px 0px var(--with-panel);
}
