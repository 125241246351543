/** @format */

.sidebarStyle {
    position: absolute;
    top: 0;
    right: 0;
    margin: 12px;
    width: 50%;
}

.tooltip {
    position: absolute;
    margin: 8px;
    padding: 4px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    max-width: 300px;
    font-size: 10px;
    z-index: 9;
    pointer-events: none;
}
