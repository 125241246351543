@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;
$celeste: #c6dae7;
$blanco: #fff;
$naranja: #ff6d6a;
$azul: #435570;
$negro: #302e2c;

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$grey-dark: $negro;
$grey-light: $beige-light;
$primary: $negro;
$link: $azul;
$info: $azul;
$success: $celeste;
$danger: $naranja;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// Import only what you need from Bulma
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/grid/_all.sass";
@import "../node_modules/bulma/sass/elements/_all.sass";
@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/components/modal.sass";
@import "../node_modules/bulma/sass/layout/hero.sass";
@import "../node_modules/bulma/sass/layout/section.sass";
