/** @format */

.center {
    margin-left: auto;
    margin-right: auto;
}

.table-container {
    overflow-y: scroll !important;
    max-height: 60vh !important;
}
